import React from 'react'
import PropTypes from 'prop-types'
import { /*QueuesGrid,*/ QueueButton } from './styled'
import { QUEUE_CLOSE_STATUS } from 'Common/constants'
import { Col, Row } from 'antd'

const setQueuesColSpan = template => {
  switch (template) {
    case '1x6':
      return 24
    case '2x6':
    default:
      return 12
  }
}
const QueuesList = props => {
  const { queues, template, onQueueClick } = props
  return (
    <Row gutter={[32, 32]}>
      {queues.map(queue => {
        const { queueId, queueName, queueIconFilename, queueStatus } = queue
        return (
          <Col span={setQueuesColSpan(template)} key={queueId}>
            <QueueButton
              disabled={queueStatus === QUEUE_CLOSE_STATUS}
              onClick={() => onQueueClick(queue)}>
              <img
                src={`/assets/img/queue-icon/${
                  queueIconFilename || 'unicorn.png'
                }`}
                alt={queueName}
              />
              {queueName}
            </QueueButton>
          </Col>
        )
      })}
    </Row>
  )
}
QueuesList.propTypes = {
  onQueueClick: PropTypes.func,
  queues: PropTypes.arrayOf(PropTypes.object),
  template: PropTypes.string,
}
export default QueuesList
