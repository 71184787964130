import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import {
  getInternalIp,
  getDispenserId,
  getConfig,
  updateCurrentQueue,
  createTicket,
} from 'Actions/app-actions'

import Loader from 'Components/Loader'
import StoreName from 'Components/StoreName'
import QueuesList from 'Components/QueuesList'
import { BigResult, Text } from 'Common/styled'

import { getQueryParam } from 'Common/utils'
import { DISPENSER_OPEN_STATUS, QUEUE_OPEN_STATUS } from 'Common/constants'

class Home extends Component {
  static propTypes = {
    createTicket: PropTypes.func,
    dispenserConfig: PropTypes.object,
    getConfig: PropTypes.func,
    queues: PropTypes.arrayOf(PropTypes.object),
    updateCurrentQueue: PropTypes.func,
  }
  state = {
    isLoading: true,
    hasConfigError: false
  }
  componentDidMount () {
    const dispenserId = getQueryParam('dispenserId')
    const ip = getQueryParam('ip')
    if (dispenserId) {
      this.getConfig(dispenserId)
    } else if (ip) {
      this.getDispenserId(ip)
    } else {
      this.getInternalIp()
    }
  }
  componentWillUnmount () {
    clearInterval(this.timer)
  }

  getInternalIp = async () => {
    const { getInternalIp } = this.props
    const { status, ip } = await getInternalIp()
    if (status === 200) {
      return this.getDispenserId(ip)
    }

    this.setState({ isLoading: false, hasConfigError: true })
  }

  getDispenserId = async ip => {
    const { getDispenserId } = this.props
    const { status, dispenserId } = await getDispenserId(ip)
    if (status === 200) {
      return this.getConfig(dispenserId)
    }

    this.setState({ isLoading: false, hasConfigError: true })
  }

  getConfig = async (dispenserId) => {
    const { getConfig } = this.props
    this.startTimer(300)

    try {
      const { status, refreshTimer } = await getConfig(dispenserId)
      this.setState({ isLoading: false, hasConfigError: status !== 200 })
      if (refreshTimer) this.startTimer(refreshTimer)
    } catch (error) {
      this.startTimer(15)
    }
  }
  startTimer (timer) {
    const { dispenserConfig } = this.props
    const dispenserId = dispenserConfig.dispenserId || getQueryParam('dispenserId')
    if (this.timer) {
      clearInterval(this.timer)
    }
    this.timer = setInterval(() => this.getConfig(dispenserId), timer * 1000)
  }

  onQueueClick = async queue => {
    const { history, updateCurrentQueue } = this.props
    if (queue.hasShoppingGuide) {
      await updateCurrentQueue(queue)
      history.push(`/shopping-guide${history.location.search}`)
    } else {
      this.createTicket(queue.queueId)
    }
  }

  createTicket = queueId => {
    const { history, createTicket } = this.props
    this.setState({ isLoading: true })
    createTicket(queueId).then(({ ticketId }) => {
      if (ticketId)
        history.push(`/queue-status/${ticketId}${history.location.search}`)
    })
  }

  renderConfigError () {
    const { ip, deviceId, dispenserId } = this.props.dispenserConfig
    return <>
      <BigResult status={'500'} />
      <Text><FormattedMessage id={'configError.title'} /></Text>
      <p>
        <small>
          <FormattedMessage id={'configError.label.ip'} values={{ ip: ip || '-' }} /><br />
          <FormattedMessage id={'configError.label.deviceId'} values={{ deviceId: deviceId || '-' }} /><br />
          <FormattedMessage id={'configError.label.dispenserId'} values={{ dispenserId: dispenserId || '-' }} />
        </small>
      </p>
    </>
  }

  render () {
    const { isLoading, hasConfigError } = this.state
    const { dispenserConfig, queues } = this.props
    const { dispenserStatus, dispenserTemplate, storeName } = dispenserConfig

    const openQueues = queues.filter(q => q.queueStatus === QUEUE_OPEN_STATUS)

    if (isLoading) return <Loader />
    if (hasConfigError) return this.renderConfigError()
    return dispenserStatus === DISPENSER_OPEN_STATUS ? (
      <>
        <StoreName>{storeName}</StoreName>
        {openQueues.length > 0 ? (
          <>
            <Text>
              <FormattedMessage id={'home.title'} />
            </Text>
            <QueuesList
              queues={openQueues}
              template={dispenserTemplate}
              onQueueClick={this.onQueueClick}
            />
          </>
        ) : (
          <>
            <BigResult status={'404'} />
            <Text>
              <FormattedMessage id={'home.noOpenQueues'} />
            </Text>
          </>
        )}
      </>
    ) : (
      ''
    )
  }
}

const mapStateToProps = state => ({
  dispenserConfig: state.app.dispenserConfig,
  queues: state.app.queues,
})
const mapDispatchToProps = {
  getInternalIp,
  getDispenserId,
  getConfig,
  updateCurrentQueue,
  createTicket,
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)
