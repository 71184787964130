import styled from 'styled-components'
import { Layout, Button as AntButton, Input, Result, Select } from 'antd'
import Button from 'Components/Button'
import { colors } from './constants'

export const AppLogo = styled.img`
  display: block;
  height: 124px;
  margin: 160px auto 0;
`
export const WhiteLayout = styled(Layout)`
  background: ${colors.white};
`
export const PaddedContent = styled(Layout.Content)`
  padding: 160px 64px 64px;
  min-height: calc(100vh - 48px);
  text-align: center;
  /* display: grid; */
  /* gap: 64px; */
  /* grid-template-rows: repeat(auto-fit, minmax(0, min-content)); */
  /* align-content: center; */
  & > * {
    margin-bottom: 48px;
  }
`

export const UppercaseButton = styled(AntButton)`
  text-transform: uppercase;
  font-weight: 500;
`
export const BigButton = styled(Button)`
  /* height: max-content; */
  height: 100%;
  width: 100%;
  padding: 32px;
  font-size: inherit;
`
export const BigInput = styled(Input)`
  & > .ant-input-group > * {
    height: max-content;
    padding: 32px;
    font-size: 300%;
  }
`
export const BigSelect = styled(Select)`
  font-size: 100%;
  & .ant-select-selection__rendered {
    margin-right: 60px;
    line-height: 50px;
  }
  & .ant-select-arrow {
    font-size: 95%;
  }
`

export const Text = styled.p`
  color: ${colors.blue};
`
export const HighlightText = styled.h2`
  color: ${colors.blue};
  /* margin-bottom: 0; */
  line-height: 1.1;
`
export const SmallText = styled.small`
  color: ${colors.blue};
  display: block;
`

export const BigResult = styled(Result)`
  & > .ant-result-image{
    width: 100%;
    height: 800px;
    & svg {
      transform: scale(2.5);
      transform-origin: top center;
    }
  }
`