import styled from 'styled-components'
import { Spin } from 'antd'
export const StyledLoader = styled(Spin)`
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${props => (props.height ? props.height : 'calc(100vh - 286px)')};
  & .anticon {
    font-size: 100px !important;
  }
`
