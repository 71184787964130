import styled from 'styled-components'
import { BigButton } from 'Common/styled'

export const QueuesGrid = styled.div`
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(2, 50%);
`
export const QueueButton = styled(BigButton)`
  & > img {
    display: block;
    margin: 0 auto 16px;
    max-height: 96px;
    filter: ${props => (props.disabled ? 'brightness(0.8)' : 'initial')};
  }
`
