import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { sendPhoneNumber } from 'Actions/app-actions'

import Loader from 'Components/Loader'
import GdprModal from 'Components/GdprModal'
import PhoneKeyboard from 'Components/PhoneKeyboard'
import {
  HighlightText,
  Text,
  SmallText,
  BigButton,
  BigInput,
  BigSelect,
} from 'Common/styled'
import { phonePrefixOptions } from 'Common/constants'

class PhoneNumber extends Component {
  static propTypes = {
    currentTicket: PropTypes.object,
    dispenserConfig: PropTypes.object,
    sendPhoneNumber: PropTypes.func,
  }
  state = {
    isLoading: false,
    isGdprModalVisible: false,
    phoneNumber: '',
    phonePrefix: '+39'
  }
  componentDidMount() {
    const { currentTicket } = this.props
    if (isEmpty(currentTicket)) {
      this.navigateToHomepage()
    }
    this.startTimer()
  }
  componentDidUpdate() {
    const { timer } = this.state
    if (timer === 0) {
      this.clearTimer()
      this.navigateToHomepage()
    }
  }
  componentWillUnmount() {
    this.clearTimer()
  }

  navigateToHomepage = () => {
    const { history } = this.props
    return history.push(`/${history.location.search}`)
  }

  openGdprModal = () => {
    this.clearTimer()
    this.setState({ isGdprModalVisible: true })
  }
  closeGdprModal = () => {
    this.startTimer()
    this.setState({ isGdprModalVisible: false })
  }

  startTimer() {
    const { dispenserConfig } = this.props
    if (this.timer) {
      this.clearTimer()
    }
    this.setState({ timer: dispenserConfig.timeout })
    this.timer = setInterval(
      () => this.setState(prevState => ({ timer: prevState.timer - 1 })),
      1000
    )
  }
  clearTimer() {
    clearInterval(this.timer)
  }

  updatePhonePrefix = prefix => {
    this.startTimer()
    this.setState({ phonePrefix: prefix })
  }

  addDigit = digit => {
    this.startTimer()
    this.setState(prevState => ({
      phoneNumber: prevState.phoneNumber + digit,
    }))
  }
  removeDigit = () => {
    this.startTimer()
    this.setState(prevState => ({
      phoneNumber: prevState.phoneNumber.slice(0, -1),
    }))
  }
  resetValue = () => {
    this.startTimer()
    this.setState({ phoneNumber: '' })
  }

  submitPhone = () => {
    const { phonePrefix, phoneNumber } = this.state
    const { currentTicket, sendPhoneNumber } = this.props
    const fullPhoneNumber = phonePrefix + phoneNumber
    if (phoneNumber) {
      this.setState({ isLoading: true })
      this.clearTimer()

      sendPhoneNumber({ id: currentTicket.id, phoneNumber: fullPhoneNumber }).then(res => {
        const newState = { isLoading: false }
        if (res === 200) newState.smsSuccess = true
        this.setState(newState)
        this.startTimer()
        this.resetValue()
      })
    }
  }

  renderFeedback() {
    return (
      <>
        <HighlightText>
          <FormattedMessage id={'phoneNumber.phoneFeedback.title'} />
        </HighlightText>
        <Text>
          <FormattedMessage id={'phoneNumber.phoneFeedback.text_1'} />
        </Text>
        <Text>
          <FormattedMessage id={'phoneNumber.phoneFeedback.text_2'} />
        </Text>
        <BigButton onClick={this.navigateToHomepage}>
          <FormattedMessage id={'queueStatus.button.goHome'} />
        </BigButton>
      </>
    )
  }
  render() {
    const {
      isLoading,
      isGdprModalVisible,
      phoneNumber,
      phonePrefix,
      smsSuccess,
    } = this.state
    return (
      <>
        <HighlightText>
          <FormattedMessage id={'phoneNumber.title'} />
        </HighlightText>
        {smsSuccess ? (
          this.renderFeedback()
        ) : (
          <>
            <BigInput
              value={phoneNumber}
              disabled
              addonBefore={<BigSelect
                value={phonePrefix}
                onChange={this.updatePhonePrefix}
              >
                {phonePrefixOptions.map(opt => (
                  <BigSelect.Option key={opt.value}>{opt.label}</BigSelect.Option>
                ))}
              </BigSelect>}
            />
            {isLoading ? (
              <Loader height={'600px'} />
            ) : (
              <PhoneKeyboard
                addDigit={this.addDigit}
                removeDigit={this.removeDigit}
                resetValue={this.resetValue}
              />
            )}
            <SmallText>
              <FormattedMessage
                id={'phoneNumber.gdpr.text'}
                values={{
                  u: chunks => <u onClick={this.openGdprModal}>{chunks}</u>,
                }}
              />
            </SmallText>
            <BigButton onClick={this.submitPhone} loading={isLoading}>
              <FormattedMessage id={'phoneNumber.button.submitPhone'} />
            </BigButton>
            <BigButton
              ghost
              onClick={this.navigateToHomepage}
              loading={isLoading}>
              <FormattedMessage id={'phoneNumber.button.goHome'} />
            </BigButton>
            <GdprModal
              isVisible={isGdprModalVisible}
              onClose={this.closeGdprModal}
            />
          </>
        )}
      </>
    )
  }
}
const mapStateToProps = state => ({
  currentTicket: state.app.currentTicket,
  dispenserConfig: state.app.dispenserConfig,
})
const mapDispatchToProps = {
  sendPhoneNumber,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PhoneNumber))
