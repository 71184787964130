import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import { connect } from 'react-redux'

import Home from 'Containers/Home'
import ShoppingGuide from 'Containers/ShoppingGuide'
import QueueStatus from 'Containers/QueueStatus'
import PhoneNumber from 'Containers/PhoneNumber'
import Page404 from 'Containers/404'
import Footer from 'Components/Footer'
import { WhiteLayout, PaddedContent } from 'Common/styled'

class App extends Component {
  static propTypes = {}

  componentDidMount () {
    window.addEventListener('touchmove', event => {
      event.preventDefault()
      event.stopImmediatePropagation()
    }, { passive: false })
  }

  render () {
    const { status, lastUpdate, dispenserConfig } = this.props
    return (
      <WhiteLayout>
        <PaddedContent>
          <Switch>
            <Route component={Home} exact path={'/'} />
            <Route component={ShoppingGuide} path={'/shopping-guide'} />
            <Route component={QueueStatus} path={'/queue-status/:ticketId'} />
            <Route component={PhoneNumber} path={'/phone-number/:ticketId'} />
            <Route component={Page404} />
          </Switch>
        </PaddedContent>
        {Footer({ status, lastUpdate, dispenserId: dispenserConfig.dispenserId })}
      </WhiteLayout>
    )
  }
}
const mapStateToProps = state => ({
  status: state.app.status,
  lastUpdate: state.app.lastUpdate,
  dispenserConfig: state.app.dispenserConfig
})
export default connect(mapStateToProps)(App)
