import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
// import { KeyboardWrapper } from './styled'
import { BigButton } from 'Common/styled'

const PhoneKeyboard = props => {
  const { addDigit, removeDigit, resetValue } = props
  const keys = [
    { value: '1', action: () => addDigit('1') },
    { value: '2', action: () => addDigit('2') },
    { value: '3', action: () => addDigit('3') },
    { value: '4', action: () => addDigit('4') },
    { value: '5', action: () => addDigit('5') },
    { value: '6', action: () => addDigit('6') },
    { value: '7', action: () => addDigit('7') },
    { value: '8', action: () => addDigit('8') },
    { value: '9', action: () => addDigit('9') },
    { value: '<', action: () => removeDigit() },
    { value: '0', action: () => addDigit('0') },
    { value: 'C', action: () => resetValue() },
  ]
  return (
    <Row gutter={[16, 16]} style={{ margin: '0 128px 64px' }}>
      {keys.map(key => (
        <Col span={8} key={key.value}>
          <BigButton onClick={key.action}>{key.value}</BigButton>
        </Col>
      ))}
    </Row>
  )
}

PhoneKeyboard.propTypes = {
  addDigit: PropTypes.func,
  removeDigit: PropTypes.func,
  resetValue: PropTypes.func,
}
export default PhoneKeyboard
