import styled from 'styled-components'
import { Layout } from 'antd'
import { colors } from 'Common/constants'

export const StyledFooter = styled(Layout.Footer)`
  text-align: center;
  padding: 0;
  height: 48px;
  font-size: 24px;
  color: ${colors.grey3};
  background: ${colors.white};
  & > * {
    &::after {
      content: '•';
      padding: 0 8px;
      font-weight: bold;
    }
    &:last-child::after {
      content: '';
      padding: 0;
    }
  }
`
