import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { injectIntl, FormattedMessage } from 'react-intl'
import QRCode from 'qrcode.react'
import { connect } from 'react-redux'

import { getTicket, sendPrinterError } from 'Actions/app-actions'

import Loader from 'Components/Loader'
import Ticket from 'Components/Ticket'
import { Text, HighlightText, BigButton } from 'Common/styled'

import { QUEUE_SHORT_WAIT_TYPE, QUEUE_LONG_WAIT_TYPE } from 'Common/constants'

class QueueStatus extends Component {
  static propTypes = {
    currentTicket: PropTypes.object,
    dispenserConfig: PropTypes.object,
    getTicket: PropTypes.func,
    sendPrinterError: PropTypes.func,
  }
  state = {
    isLoading: true,
    printError: false,
  }
  componentDidMount() {
    const { match, currentTicket, dispenserConfig } = this.props
    const ticketId = currentTicket.id || match.params.ticketId
    if (isEmpty(dispenserConfig)) {
      this.navigateToHomepage()
    }
    if (ticketId) {
      this.getTicket(ticketId)
    }
  }
  componentDidUpdate() {
    const { timer } = this.state
    if (timer === 0) {
      this.clearTimer()
      this.navigateToHomepage()
    }
  }
  componentWillUnmount() {
    this.clearTimer()
  }

  navigateToHomepage = () => {
    const { history } = this.props
    return history.push(`/${history.location.search}`)
  }
  navigateToInsertPhone = () => {
    const { history, currentTicket } = this.props
    return history.push(
      `/phone-number/${currentTicket.id}${history.location.search}`
    )
  }

  getTicket(ticketId) {
    const { dispenserConfig, getTicket } = this.props
    getTicket(ticketId).then(res => {
      if (res === 404) this.navigateToHomepage()
      this.startTimer()
      if (dispenserConfig.hasPrinter) {
        this.printTicket()
      } else {
        this.setState({ isLoading: false })
      }
    })
  }
  startTimer() {
    const { dispenserConfig } = this.props
    if (this.timer) {
      this.clearTimer()
    }
    this.setState({ timer: dispenserConfig.timeout })
    this.timer = setInterval(
      () => this.setState(prevState => ({ timer: prevState.timer - 1 })),
      1000
    )
  }
  clearTimer() {
    clearInterval(this.timer)
  }

  printTicket() {
    const that = this
    const { currentTicket, dispenserConfig, sendPrinterError } = this.props
    const { ticketNumber, queueName, storeName } = currentTicket
    const {
      dispenserId,
      printerId,
      printerAddress,
      printerDeviceId,
    } = dispenserConfig
    const { ePOSBuilder, CanvasPrint } = window.epson
    this.setState({ isLoading: true })

    // Printer config
    const builder = new ePOSBuilder()
    const printerUrl = `${printerAddress}/cgi-bin/epos/service.cgi?devid=${printerDeviceId}`
    const epos = new CanvasPrint(printerUrl)

    // Draw image on canvas
    const canvas = document.getElementById('canvas')
    const context = canvas.getContext('2d')

    const img = new Image()
    img.src = `${process.env.REACT_APP_API_URL}/printers/ticketImage?ticketNumber=${ticketNumber}&queueName=${queueName}&storeName=${storeName}`
    img.crossOrigin = 'Anonymous'
    img.onload = function () {
      context.drawImage(img, 0, 0, canvas.width, canvas.height)

      builder.addImage(context, 0, 0, canvas.width, canvas.height)
      builder.addCut(builder.CUT_FEED)
      const message = builder.toString()

      epos.onreceive = function (res) {
        console.log('PRINT RES', res)
        const newState = { isLoading: false }
        if (!res.success) {
          newState.printError = true
          sendPrinterError({ dispenserId, printerId, errorCode: res.code })
        }
        that.setState(newState)
      }
      epos.onerror = function (err) {
        console.log('ERROR', err)
        sendPrinterError({ dispenserId, printerId, errorCode: 'Unreachable printer' })
        that.setState({ isLoading: false, printError: true })
      }

      epos.send(message)
    }
  }

  renderQueueWait() {
    const { currentTicket } = this.props
    switch (currentTicket.waitType) {
      case QUEUE_SHORT_WAIT_TYPE:
        return (
          <>
            <Text>
              <FormattedMessage
                id={'queueStatus.shortWait.text'}
                values={{ linebreak: <br /> }}
              />
            </Text>
            <BigButton onClick={this.navigateToHomepage}>
              <FormattedMessage id={'queueStatus.button.goHome'} />
            </BigButton>
          </>
        )
      case QUEUE_LONG_WAIT_TYPE:
        return (
          <>
            <Text>
              <FormattedMessage
                id={'queueStatus.longWait.text'}
                values={{ linebreak: <br /> }}
              />
            </Text>
            <BigButton onClick={this.navigateToInsertPhone}>
              <FormattedMessage id={'queueStatus.button.insertPhone'} />
            </BigButton>
            <BigButton ghost onClick={this.navigateToHomepage}>
              <FormattedMessage id={'queueStatus.button.insertNoPhone'} />
            </BigButton>
          </>
        )
      default:
        return ''
    }
  }
  render() {
    const { isLoading, printError } = this.state
    const { currentTicket, dispenserConfig } = this.props
    const {id, ticketNumber } = currentTicket
    const qrURL = `${process.env.REACT_APP_FE_CUSTOMER_URL}/change-owner/${id}?ticket=${ticketNumber}`
    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Ticket number={ticketNumber} />
            {dispenserConfig.hasPrinter && !printError ? (
              <HighlightText>
                <FormattedMessage id={'queueStatus.hasPrinter.text'} />
              </HighlightText>
            ) : (
              <HighlightText>
                <FormattedMessage id={'queueStatus.hasNoPrinter.text'} />
              </HighlightText>
            )}
            <QRCode value={qrURL} size={250} />
            {this.renderQueueWait()}
          </>
        )}
        <canvas
          id={'canvas'}
          width={dispenserConfig.printerPaperWidth}
          height={450}
          style={{ display: 'none' }}
        />
      </>
    )
  }
}
const mapStateToProps = state => ({
  currentTicket: state.app.currentTicket,
  dispenserConfig: state.app.dispenserConfig,
})
const mapDispatchToProps = {
  getTicket,
  sendPrinterError,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(QueueStatus))
