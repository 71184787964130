import moment from 'moment'
import { omit } from 'ramda'
import {
  GET_INTERNAL_IP,
  GET_DISPENSER_ID,
  GET_CONFIG,
  UPDATE_CURRENT_QUEUE,
  UPDATE_CURRENT_TICKET,
  SEND_PHONE_NUMBER,
  SEND_PRINTER_ERROR,
} from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const getInternalIp = () => async dispatch => {
  try {
    const res = await api.internalIp.get()
    const ip = res.data.ip.private[0]

    dispatch({ type: GET_INTERNAL_IP, payload: ip })
    
    return { status: res?.status, ip }
  } catch (error) {
    return { status: error.response?.status || 500 }
  }
}

export const getDispenserId = ip => async dispatch => {
  try {
    const res = await api.dispenser.getId(ip)
    const { configId: dispenserId, deviceId } = res.data
    
    dispatch({ type: GET_INTERNAL_IP, payload: ip })
    dispatch({ type: GET_DISPENSER_ID, payload: { deviceId, dispenserId } })

    return { status: res?.status, dispenserId }
  } catch (error) {
    return { status: error.response?.status || 500 }
  }
}

export const getConfig = dispenserId => async dispatch => {
  try {
    const res = await api.dispenser.config(dispenserId)

    const { config } = res.data
    dispatch({
      type: GET_CONFIG,
      payload: {
        dispenserConfig: omit(['queues'], config),
        queues: config?.queues || [],
        status: 'OK',
        lastUpdate: moment().format('HH:mm:ss')
      },
    })
    return { status: res.status, refreshTimer: config?.refreshTimer }
  } catch (error) {
    dispatch({
      type: GET_CONFIG, payload: {
        status: 'KO',
        lastUpdate: moment().format('HH:mm:ss')
      }
    })
    console.error(error)
    throw error
  }
}

export const updateCurrentQueue = queue => async dispatch => {
  return dispatch({ type: UPDATE_CURRENT_QUEUE, payload: queue })
}

export const getTicket = id => async dispatch => {
  try {
    const res = await api.ticket.retrieve(id)
    return dispatch(updateCurrentTicket(res.data.ticket))
  } catch (error) {
    const { status } = error.response
    if (status === 404) return status
    return handleServerError(error)
  }
}
export const createTicket = queueId => async dispatch => {
  try {
    const res = await api.ticket.create(queueId)
    const ticket = res.data.createdTicket
    dispatch(updateCurrentTicket(ticket))
    return { ticketId: ticket.id }
  } catch (error) {
    return handleServerError(error)
  }
}
export const updateCurrentTicket = ticket => {
  return { type: UPDATE_CURRENT_TICKET, payload: ticket }
}

export const sendPhoneNumber = ({ id, phoneNumber }) => async dispatch => {
  try {
    await api.sms.submit({ id, phoneNumber })
    dispatch({ type: SEND_PHONE_NUMBER })
    return 200
  } catch (error) {
    return handleServerError(error)
  }
}

export const sendPrinterError = ({
  dispenserId,
  printerId,
  errorCode,
}) => async dispatch => {
  try {
    await api.printer.error({ dispenserId, printerId, errorCode })
    return dispatch({ type: SEND_PRINTER_ERROR })
  } catch (error) {
    return handleServerError(error)
  }
}
