export const GET_INTERNAL_IP = 'GET_INTERNAL_IP'
export const GET_DISPENSER_ID = 'GET_DISPENSER_ID'

export const GET_CONFIG = 'GET_CONFIG'

export const UPDATE_CURRENT_QUEUE = 'UPDATE_CURRENT_QUEUE'
export const UPDATE_CURRENT_TICKET = 'UPDATE_CURRENT_TICKET'

export const SEND_PHONE_NUMBER = 'SEND_PHONE_NUMBER'

export const SEND_PRINTER_ERROR = 'SEND_PRINTER_ERROR'
