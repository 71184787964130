import React from 'react'
import PropTypes from 'prop-types'
import { UppercaseButton } from 'Common/styled'
const Button = props => {
  const { children, type, ...otherProps } = props
  return (
    <UppercaseButton
      {...otherProps}
      size={'large'}
      type={type ? type : 'primary'}>
      {children}
    </UppercaseButton>
  )
}
Button.propTypes = {
  type: PropTypes.string,
}
export default Button
