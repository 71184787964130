import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { FullWidthModal } from './styled'
import Button from 'Components/Button'

const GdprModal = props => {
  const { intl, isVisible, onClose } = props
  return (
    <FullWidthModal
      centered
      visible={isVisible}
      title={intl.formatMessage({ id: 'phoneNumber.gdpr.linkText' })}
      onCancel={onClose}
      footer={<Button onClick={onClose}>{'OK'}</Button>}>
      <iframe
        title={'GDPR'}
        width={'100%'}
        height={900}
        src={intl.formatMessage({ id: 'phoneNumber.gdpr.link' })}
      />
    </FullWidthModal>
  )
}

GdprModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
}
export default injectIntl(GdprModal)
