import axios from 'axios'

const BE_API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    api_key: process.env.REACT_APP_API_KEY,
  },
})

const INTERNAL_IP_API = axios.create({
  baseURL: process.env.REACT_APP_INTERNAL_IP_API_URL,
})

const MAGICINFO_TRANSCODE_API = axios.create({
  baseURL: process.env.REACT_APP_MAGICINFO_TRANSCODE_API_URL,
  headers: {
    'X-Gateway-APIKey': process.env.REACT_APP_MAGICINFO_TRANSCODE_API_KEY,
  },
})

export default {
  internalIp: {
    get: () => INTERNAL_IP_API.get('/'),
  },
  dispenser: {
    config: dispenserId =>
      BE_API.get('/dispensers/getConfiguration', { params: { dispenserId } }),
    getId: ip =>
      MAGICINFO_TRANSCODE_API.get(process.env.REACT_APP_MAGICINFO_TRANSCODE_API_ENDPOINT, { params: { ip } }),
  },
  printer: {
    error: ({ dispenserId, printerId, errorCode }) =>
      BE_API.post('/printers/submitError', {
        dispenserId,
        printerId,
        errorCode,
      }),
  },
  ticket: {
    create: queueId =>
      BE_API.post('/mixed_ticket/create', {
        queueId,
        creationType: 'DISPENSER',
      }),
    retrieve: id =>
      BE_API.get('/dispensers/retrieve', {
        params: { id },
        headers: { client_key: 'DISPENSER' },
      }),
  },
  sms: {
    submit: ({ id, phoneNumber }) =>
      BE_API.post('/mixed_sms/submit', { id, phoneNumber }),
  },
}
