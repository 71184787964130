import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { FormattedMessage } from 'react-intl'
import QRCode from 'qrcode.react'
import { connect } from 'react-redux'

import { createTicket } from 'Actions/app-actions'

import Loader from 'Components/Loader'
import StoreName from 'Components/StoreName'
import { Text, BigButton } from 'Common/styled'
import { Col, Row } from 'antd'

class ShoppingGuide extends Component {
  static propTypes = {
    createTicket: PropTypes.func,
    currentQueue: PropTypes.object,
    dispenserConfig: PropTypes.object,
  }
  state = {
    isLoading: false,
    otherProduct: false,
  }
  componentDidMount() {
    const { dispenserConfig, currentQueue } = this.props
    if (isEmpty(dispenserConfig) || isEmpty(currentQueue)) {
      this.navigateToHomepage()
    }
    this.startTimer()
  }
  componentDidUpdate() {
    const { timer } = this.state
    if (timer === 0) {
      this.clearTimer()
      this.navigateToHomepage()
    }
  }
  componentWillUnmount() {
    this.clearTimer()
  }

  navigateToHomepage = () => {
    const { history } = this.props
    return history.push(`/${history.location.search}`)
  }

  startTimer() {
    const { dispenserConfig } = this.props
    if (this.timer) {
      this.clearTimer()
    }
    this.setState({ timer: dispenserConfig.timeout })
    this.timer = setInterval(
      () => this.setState(prevState => ({ timer: prevState.timer - 1 })),
      1000
    )
  }
  clearTimer() {
    clearInterval(this.timer)
  }

  createTicket = queueId => {
    const { history, createTicket } = this.props
    this.setState({ isLoading: true })
    createTicket(queueId).then(({ ticketId }) => {
      if (ticketId)
        history.push(`/queue-status/${ticketId}${history.location.search}`)
    })
  }
  selectOtherProduct = () => {
    this.setState({ otherProduct: true })
    this.startTimer()
  }

  renderOtherProduct() {
    const { dispenserConfig } = this.props
    return (
      <>
        <Text>
          <FormattedMessage id={'shoppingGuide.findItems.text_1'} />
        </Text>
        <Text>
          <FormattedMessage id={'shoppingGuide.findItems.text_2'} />
        </Text>
        <QRCode value={dispenserConfig.findItemsUrl} size={400} />
        <BigButton ghost onClick={this.navigateToHomepage}>
          <FormattedMessage id={'shoppingGuide.button.goHome'} />
        </BigButton>
      </>
    )
  }
  renderFamilies() {
    const { currentQueue } = this.props
    const { submenu, queueId, hasMoreButton } = currentQueue
    return (
      <>
        <Text>
          <FormattedMessage id={'shoppingGuide.title'} />
        </Text>
        <Row gutter={[32, 32]} style={{ marginBottom: 64 }}>
          {submenu?.map(family => (
            <Col span={24} key={family.familyId}>
              <BigButton onClick={() => this.createTicket(queueId)}>
                {family.familyName}
              </BigButton>
            </Col>
          ))}
          {hasMoreButton ? <Col span={24}>
            <BigButton onClick={this.selectOtherProduct}>
              <FormattedMessage id={'shoppingGuide.button.more'} />
            </BigButton>
          </Col> : null}
        </Row>
        <BigButton ghost onClick={this.navigateToHomepage}>
          <FormattedMessage id={'shoppingGuide.button.goBack'} />
        </BigButton>
      </>
    )
  }
  render() {
    const { isLoading, otherProduct } = this.state
    const { dispenserConfig } = this.props
    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <StoreName>{dispenserConfig.storeName}</StoreName>
            {otherProduct ? this.renderOtherProduct() : this.renderFamilies()}
          </>
        )}
      </>
    )
  }
}
const mapStateToProps = state => ({
  dispenserConfig: state.app.dispenserConfig,
  currentQueue: state.app.currentQueue,
})
const mapDispatchToProps = {
  createTicket,
}
export default connect(mapStateToProps, mapDispatchToProps)(ShoppingGuide)
