export const DISPENSER_OPEN_STATUS = 'OPEN'
export const DISPENSER_CLOSE_STATUS = 'CLOSE'

export const QUEUE_OPEN_STATUS = 'OPEN'
export const QUEUE_CLOSE_STATUS = 'CLOSE'

export const QUEUE_SHORT_WAIT_TYPE = 'SHORT'
export const QUEUE_LONG_WAIT_TYPE = 'LONG'

export const phonePrefixOptions = [
  { value: '+39', label: '🇮🇹 +39' },
  { value: '+41', label: '🇨🇭 +41' },
]
