import {
  GET_INTERNAL_IP,
  GET_DISPENSER_ID,
  GET_CONFIG,
  UPDATE_CURRENT_QUEUE,
  UPDATE_CURRENT_TICKET,
} from '../action-types'

const initialState = {
  dispenserConfig: {
    dispenserId: undefined,
    dispenserStatus: 1,
    storeId: '98',
    storeName: 'Hogwarts',
    refreshTimer: '15',
    timeout: '60',
    screensaverFilename: 'ss001.mp4',
    hasPrinter: 0,
    printerId: 1,
  },
  queues: [],
  currentQueue: {},
  currentTicket: {},
  status: 'OK'
}
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_INTERNAL_IP:
      return { ...state, dispenserConfig: { ...state.dispenserConfig, ip: payload } }
    case GET_DISPENSER_ID:
      return { ...state, dispenserConfig: { ...state.dispenserConfig, ...payload } }
    case GET_CONFIG:
      return { ...state, ...payload, dispenserConfig: { ...state.dispenserConfig, ...payload.dispenserConfig } }
    case UPDATE_CURRENT_QUEUE:
      return { ...state, currentQueue: payload }
    case UPDATE_CURRENT_TICKET:
      return { ...state, currentTicket: payload }
    default:
      return state
  }
}
